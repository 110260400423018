import tools from "../../../extends/tools.js";
import { reg, smsCode } from "../../common/js/api"; //注册
export default {
    //注册
    async registers() {
        reg(this.saveData).then(res => {
            if (res.code == 200) {
                this.$message({
                    type: 'success',
                    message: '注册成功，请完善资料'
                });
                // 保存信息，完善资料成功后静默登录
                localStorage.setItem('account', this.saveData.tel);
                localStorage.setItem('password', this.saveData.password_copy);
                localStorage.setItem('uid', res.data.id);
                localStorage.setItem('isReg', true);

                // 跳转选择角色
                this.$router.push({
                    path: "/setRole?id=" + res.data.id
                })
            } else {
                this.$message({
                    type: "error",
                    message: res.msg,
                });
            }
        }).catch(err => {})
    },
    // 发送短信验证码
    async sendSms() {
        var phone = parseInt(this.saveData.tel).toString();
        if (phone.length != 11) {
            this.$alert('手机号格式有误！', '错误提示', {
                confirmButtonText: '确定'
            });
            return;
        }
        smsCode(phone).then(res => {
            if (res.code == 200) {
                this.count = 60;
                let TIMER = setInterval(() => {
                    this.count -= 1;
                    if (this.count <= 0) {
                        clearInterval(TIMER);
                    }
                }, 1000);

                this.$message({
                    type: "success",
                    message: '获取验证码成功！',
                });

            } else {
                this.$message({
                    type: "error",
                    message: res.msg,
                });
            }
        }).catch(err => {
            this.$message({
                type: "error",
                message: err,
            });
        });

    },
    kai() {
        if (!this.saveData.checked) {
            this.$alert('请先勾选并同意《服务协议》', '错误提示', {
                confirmButtonText: '确定'
            });
            return;
        }

        if (!this.saveData.invitation_code) {
            this.$alert('请输入邀请码', '错误提示', {
                confirmButtonText: '确定'
            });
            return;
        }

        //校验帐号密码   
        if (this.saveData.password_copy.length < 6 || this.saveData.password_copy.length < 6) {
            this.$message({
                type: "error",
                message: '帐号或密码格式不合法',
            });
            return;
        }
        if (this.saveData.sms_code.length < 6) {
            this.$message({
                type: "error",
                message: '请输入6位验证码',
            });
            return;
        }

        // 
        if (this.saveData.password_copy !== this.saveData.password2) {
            this.$message({
                type: "error",
                message: '两次输入的密码不一致',
            });
            return;
        }

        this.saveData.password = tools.encrypt(this.saveData.password_copy);

        this.registers();
    },
    //跳转
    to_login() {
        this.$router.push({
            path: '/home'
        })
    }
}