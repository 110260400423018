<template>
    <div class="con">
        <div class="div_container">
            <div class="reg">
                <div class="title">
                    <p style="font-size:48px;color:#2182fa;">欢迎使用店铺总管</p>
                    <p style="font-size:16px;color:#000;margin-top:15px;">此刻开始我们将携手管理您的店铺</p>
                </div>
                <el-form ref="form"  label-width="80px" size="small" style="margin:30px 0 0 20px ;">
                    <el-form-item label="联系电话">
                        <input type="text" class="line_input" v-model="saveData.contact_phone">
                    </el-form-item>
                    <!-- <el-form-item label="微　　信">
                        <input type="text" class="line_input">
                    </el-form-item> -->
                    <el-form-item label="公司名称">
                        <input type="text" v-model="saveData.company" class="line_input">
                    </el-form-item>
                    <el-form-item label="邮箱地址">
                        <input type="text" class="line_input" v-model="saveData.email">
                    </el-form-item>
                </el-form>

                <el-form ref="form" label-width="80px" size="small" style="margin:50px 0 0 20px ;">
                    <el-form-item label="手机号码">
                        <el-input v-model="saveData.tel" style="width:85%;" ></el-input>
                    </el-form-item>
                    <el-form-item label="输入密码">
                        <el-input v-model="saveData.password_copy" style="width:85%;"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码">
                        <el-input v-model="saveData.password2" style="width:85%;"></el-input>
                    </el-form-item>

                    <el-form-item label="邀请码">
                        <el-input v-model="saveData.invitation_code" style="width:85%;"></el-input>
                    </el-form-item>

                    <el-form-item label="验证码　">
                        <el-input v-model="saveData.sms_code" style="width:130px;"></el-input>
                        <el-button @click="sendSms">发送验证码</el-button>
                    </el-form-item>
                    <el-form-item label="">
                        <el-checkbox v-model="saveData.checked">勾选即代表您同意遵守《服务协议》</el-checkbox>
                    </el-form-item>
                </el-form>
                <el-button type="primary" style="margin: 0 0 0 200px;width:130px;" @click="kai"> 注 册 </el-button>
            </div>
        </div>   
        <alikey></alikey>
    </div>
</template>

<script>
    import {reg,smsCode} from "../../common/js/api"; //注册
    import Alikey from '../../../components/common/alikey.vue';
    import tools from "../../../extends/tools.js";
    import methods from './methods.js';

    export default {
        name: "HomeHospital",
        components:{
            Alikey
        },
        data() {
            return {
                labelPosition: 'right',
                saveDataInit:null,
                saveData: {
                    tel: '',
                    sms_code: '',
                    password: '',
                    password_copy: '',
                    password2: '',
                    contact_phone: '',
                    company: '',
                    email: '',
                    checked:false,
                    invitation_code:''
                },
                show: true,
                inputValueTop:'',//记录账号内容
                password:"",    //密码
                inputValueBot:'', //记录密码内容
                sms_code:'',     //记录手机验证码
                count:0
            }
        },
        destroyed(){
            this.saveData = tools.copy(this.saveDataInit);
        },
        mounted(){
            if(this.saveDataInit === null){
                this.saveDataInit = this.saveData;
            }
        },
        methods: methods,
        created(){

        }
    }
</script>

<style scoped lang="less">
@import url('../less/register.less');
</style>