<template>
    <div class="home">

        <home-hospital ></home-hospital>
        <!--<router-view></router-view>-->
    </div>
</template>
<script>
    import Qs from 'qs';
    import HomeHospital from "./base/HomeHospital";

    export default {
        name: "Register",
        components:{
            HomeHospital,

        },
        data(){
            return {
                inputValueTop:'',//记录账号内容
                inputValueBot:'', //记录密码内容
                inputCode:'',     //记录手机验证码
                show: true,
            }
        },
        created(){

        },
        methods:{
            //验证码倒计时
            getCode(){
                if( this.inputValueTop!=null&&this.inputValueTop!=""&&this.inputValueTop.length===11) {
                    const TIME_COUNT = 60;
                    if (!this.timer) {
                        this.count = TIME_COUNT;
                        this.show = false;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                            } else {
                                this.show = true;
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1000)
                    }
                    this.Agsmscode()
                }
                else {
                    alert("请输入正确的11位手机号")
                }
            },
        },
        // filters:{
        //     TitleFilter(value){
        //         //console.log(value.split(0).join().split(2)[2].split(',')[1]);
        //         return value.split(0).join().split(2)[2].split(',')[1]
        //     }
        // },

    }
</script>

<style scoped lang="less">
.home{
    .home-le{
        display: flex;
        justify-content: center;
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        margin: auto;
        z-index:4;
        width:100px;
        max-height:346px;
        padding:15px 0;
        background-color:#910000;
        cursor: pointer;
        p{
            //width:46px;
            line-height:29px;
            letter-spacing:5px;
            font-size:18px;
            color:#fff;
            writing-mode: vertical-lr;
        }
        .img1{
            position: absolute;
            right:0;
            top:0;
            width:25px;
        }
    }
    .home-ri{
        display: flex;
        justify-content: center;
        position:fixed;
        top:0;
        bottom:0;
        right:0;
        margin: auto;
        z-index:4;
        width:100px;
        max-height:346px;
        padding:15px 0;
        background-color:#910000;
        cursor: pointer;
        p{
            line-height:29px;
            letter-spacing:13px;
            font-size:18px;
            color:#fff;
            writing-mode:vertical-lr;
        }
        .img1{
            position: absolute;
            right:0;
            top:0;
            width:25px;
        }
    }
}
</style>
